export const TitleMap: { [id: string]: string } = {
    "/": "Medicor Imaging - Serving the Global Dental, ENT, and Radiology Industries with High-Quality DICOM and PACS Solutions",
    "/industry": "Industry - Medicor Imaging",
    "/industry/dental-schools": "Dental Schools - Solutions by Industry - Medicor Imaging",
    "/industry/federally-qualified-health-centers": "Federally Qualified Health Centers - Solutions by Industry - Medicor Imaging",
    "/industry/government-agencies": "Government Agencies - Solutions by Industry - Medicor Imaging",
    "/industry/medical-imaging-centers": "Medical Imaging Centers - Solutions by Industry - Medicor Imaging",
    "/industry/multi-clinic-dental-organizations": "Multi-Clinic Dental Organizations - Solutions by Industry - Medicor Imaging",
    "/products": "Product Overview - Medicor Imaging",
    "/products/mipacs-storage-server": "MiPACS Storage Server - Medicor Imaging",
    "/products/mipacs-dicom-router": "MiPACS DICOM Router+ - Medicor Imaging",
    "/products/mipacs-hl7-addon": "MiPACS Storage Server HL7 Add-on - Medicor Imaging",
    "/products/mipacs-modality-worklist": "MiPACS Modality Worklist - Medicor Imaging",
    "/products/mipacs-html5-web-viewer": "MiPACS HTML5 Web Viewer - Medicor Imaging",
    "/products/mipacs-dental-enterprise-solution": "MiPACS Dental Enterprise Solution - Medicor Imaging",
    "/products/mipacs-dental-enterprise-viewer": "MiPACS Dental Enterprise Viewer - Medicor Imaging",
    "/products/mipacs-demo-request": "MiPACS Demo Request - Medicor Imaging",
    "/products/compatibility": "Device Compatibility - Medicor Imaging",
    "/support": "Support - Medicor Imaging",
    "/support/measurement-calibration": "Measurement Calibration - Medicor Imaging",
    "/support/locating-deleted-files": "Locating Deleted Files - Medicor Imaging",
    "/support/using-patient-id-banner": "Using Patient ID Banner - Medicor Imaging",
    "/contact": "Contact - Medicor Imaging",
    "/contact/subscription": "Medicor Newsletter Subscription Mangement - Medicor Imaging",
    "/corporate": "Corporate - Medicor Imaging",
    "/corporate/about": "About - Medicor Imaging",
    "/corporate/careers": "Careers - Medicor Imaging",
    "/corporate/events": "Events - Medicor Imaging",
    "/corporate/references": "References - Medicor Imaging",
    "/corporate/pressroom/*": "Press Room - Medicor Imaging",
    "/corporate/pressroom/AnnualMiPACS_2023": "Press Room - Medicor Imaging",
    "/corporate/pressroom/AnnualMiPACS9th_2018": "Press Room - Medicor Imaging",
    "/corporate/pressroom/MiPACSSelected_2016": "Press Room - Medicor Imaging",
    "/corporate/pressroom/MiPACSRecieve_2014": "Press Room - Medicor Imaging",
    "/corporate/pressroom/MiPACSInstalled_2012": "Press Room - Medicor Imaging",
    "/corporate/pressroom/MiPACSFourtySchools_2011": "Press Room - Medicor Imaging",
    "/corporate/pressroom/MedicorCEENTA_2010": "Press Room - Medicor Imaging",
    "/corporate/pressroom/MedicorFinalAir_2010": "Press Room - Medicor Imaging",
    "/corporate/pressroom/MedicorSelectedProvider_2009": "Press Room - Medicor Imaging",
    "/corporate/pressroom/MedicorSelectedPACS_2009": "Press Room - Medicor Imaging",
    "/corporate/pressroom/MiPACSThreeVA_2008": "Press Room - Medicor Imaging",
    "/corporate/pressroom/MedicorFeaturedProvider_2008": "Press Room - Medicor Imaging",
    "/corporate/pressroom/UnitedAirSelectsMedicor_2007": "Press Room - Medicor Imaging",
    "/corporate/pressroom/DeptVeteransImplements_2007": "Press Room - Medicor Imaging",
    "/corporate/pressroom/UNLVImplementsMiPACS_2004": "Press Room - Medicor Imaging",
    "/corporate/pressroom/MedicorReleasesMiPACS_2001": "Press Room - Medicor Imaging",
    "/corporate/pressroom/LEADTOOLSUpdated_2001": "Press Room - Medicor Imaging",
    "/corporate/pressroom/LEADAnnouncesFormation_2001": "Press Room - Medicor Imaging",
    "/corporate/pressroom/LEADIntroducesMedical_1999": "Press Room - Medicor Imaging",
    "/corporate/privacy": "Privacy - Medicor Imaging",
    "/corporate/case-studies": "Case Studies - Medicor Imaging",
    "/corporate/case-studies/charlotte-eye-ears-nose-and-throat-associates": "Charlotte Eye Ears Nose & Throat Associates - Medicor Imaging",
    "/corporate/case-studies/tufts-university-school-of-dental-medicine": " Tufts University School of Dental Medicine - Medicor Imaging",
    "/corporate/case-studies/university-of-florida-college-of-dentistry": "University of Florida College of Dentistry - Medicor Imaging",
    "/corporate/case-studies/university-of-kansas-city-missouri-school-of-dentistry": "University of Kansas City Missouri School of Dentistry - Medicor Imaging",
    "/corporate/case-studies/university-of-nevada-at-las-vegas-school-of-dental-medicine": "University of Nevada at Las Vegas School of Dental Medicine - Medicor Imaging",
    "/corporate/case-studies/university-of-texas-health-and-science-center-at-san-antonio": "University of Texas Health and Science Center at San Antonio",
    "/mipacs-user-meeting": "MiPACS User Meeting - Medicor Imaging",
    "/search": "Search - Medicor Imaging",
  }