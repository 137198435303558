
import internal from "stream";
import styled from "styled-components";
import { StyleProps } from "../../Interfaces";

const MainContent = styled.div<StyleProps>`
    display: flex;
    flex-direction: column;
    width: 25%;
    justify-content: center;
    @media only screen and (max-width:850px ) {
        width: 90%;
        margin: 10px auto;
    }
`;

const TopDiv = styled.div`
    display: flex;
    flex-direction: row;  
    align-items: center;

    div{
        margin-left: 75px;
        display: flex;
        flex-direction: column;
        @media only screen and (max-width:850px ) {
            margin-left: 50px;
        }
    }
    p{
        font-size: 16px;
        @media only screen and (min-width: 2400px) {
        
        font-size: 20px;
        }

        @media only screen and (max-width:850px ) {
            font-size: 14px;
        }
    }
`;

const CornerImage = styled.img<StyleProps>`
    width: 125px;
    height: 125px;

    @media only screen and (max-width:850px ) {
        width: 75px;
        height: 75px;
    }
`;

const NameText = styled.h3`
 font-size: 28px;

    @media only screen and (min-width: 2400px) {
        
        font-size: 30px;
    }
    @media only screen and (max-width:850px ) {
            font-size: 20px;
    }
`;


const BodyText = styled.div`
    width: 100%;
    p{
        font-size: 18px;
        @media only screen and (min-width: 2400px) {
        
        font-size: 20px;
        }
        @media only screen and (max-width:850px ) {
            font-size: 14px;
        }
    }
`;



const HomePageTestimonial = ({ image, textName, textJob, textDesc }: any) => {
    return (
        <MainContent>
            <TopDiv>
                <CornerImage src={image} alt="Image of organization using Medicor Imaging"/>
                <div>
                    <NameText>{textName}</NameText>
                    <p>{textJob}</p>
                </div>
            </TopDiv>
            <BodyText>
                <p>{textDesc}</p>
            </BodyText>
        </MainContent>
    );
}

export default HomePageTestimonial;