import { useState, useContext } from 'react';
import internal from "stream";
import styled from "styled-components";
import { StyleProps } from "../../Interfaces";
import { WindowWidthContext } from '../../App';

import { Link } from 'react-router-dom';


const MainDiv = styled.div<Props>`
    display: flex;
    position: relative;
    border: 2px solid #e5e7f0;
    border-radius:  30px;
    background-color: #feffff;
    height: 100%;
    margin: 2%;

    ${props => props.isActive && `
        background-color: #272d48;
        color: #feffff;
        cursor: pointer;
        button{
            background-color: #feffff;
            color: #272d48;
            cursor: pointer;
        }
    `}
    
    &:hover{        
        background-color: #272d48;
        color: #feffff;

        button{
            background-color: #feffff;
            color: #272d48;
            cursor: pointer;
        }
    }
    @media only screen and (max-width:1450px ) {
        position: static;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

`;

const CornerImage = styled.img<StyleProps>`
  height: 60px;
  width: 60px;
  top: 5%;
  right: 5%;
  position: absolute;

    @media only screen and (min-width: 2400px) {
        
    height: 80px;
    width: 80px;
    }

    @media only screen and (max-width:1450px ) {
        position: static;
    }
`;

const TitleText = styled.h3`
    font-size: 26px;
    left: 5%;
    top: 25%;
    position: absolute;
    width: 70%;
    top: 0px;

    @media only screen and (min-width: 2400px) {
        
        font-size: 32px;
    }
    @media only screen and (max-width:1450px ) {
        position: static;
        font-size: 20px;
    }
    
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;    
    align-items: center;
    justify-content: space-evenly;
`;

const BodyText = styled.p`
    position: absolute;
    left: 5%;
    top: 27%; 
    width: 80%;

    @media only screen and (min-width: 2400px) {
        
        left: 5%;
        top: 30%; 
    }
    @media only screen and (max-width:1450px ) {
        font-size: 14px;
        position: static;
    }
    @media only screen and (max-width:850px ) {
        position: static;
        font-size: 14px;
    }
`;

const Button = styled.button`
    width: 100px;
    height: 50px;
    position: absolute;
    left: 5%;
    top: 80%; 
    border-radius:  10px;
    border-color: transparent;
    background-color:#37b4fe;
    color: #feffff;

    @media only screen and (max-width:1450px ) {
        position: static;
        margin-bottom: 5px;
    }
`;

interface Props {
    isActive?: boolean;
}


const ExpandingSquare = ({ textT, textB, image, imageRoll, expandSquarebuttonLink, isActive }: any) => {

    const [trueImage, setTrueImage] = useState(isActive ? imageRoll : image);

    const windowWidth = useContext(WindowWidthContext);

    const handleMouseEnter = () => {
        setTrueImage(imageRoll);
    }

    const handleMouseLeave = () => {
        setTrueImage(image);
    }


    if (windowWidth < 850) {
        return (
            <MainDiv isActive={isActive} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <TitleWrapper>
                    <TitleText>{textT}</TitleText>
                    <CornerImage src={trueImage} alt="image relating to the Medicor Imaging product description" />
                </TitleWrapper>

                <BodyText>{textB}</BodyText>

                <Link to={expandSquarebuttonLink}>
                    <Button>Learn More</Button>
                </Link>
            </MainDiv >
        );
    }

    return (
        <MainDiv isActive={isActive} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <TitleText>{textT}</TitleText>
            <CornerImage src={trueImage} alt="image relating to the Medicor Imaging product description" />

            <BodyText>{textB}</BodyText>

            <Link to={expandSquarebuttonLink}>
                <Button>Learn More</Button>
            </Link>
        </MainDiv >
    );
}

export default ExpandingSquare;