import styled from "styled-components";
import { Link } from "react-router-dom";
import { StyleProps } from "../../Interfaces";

export const Navbar = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
`;

export const TopContainer = styled.div`
    width: 100%; 
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const IconPack = styled.div`
    display: flex;
    margin-left: 5%;
    align-items: center;
    @media only screen and (max-width:850px ) {
        margin-left: 0;
    }

`;

export const MobileIconTextWrapper = styled.div<StyleProps>`
    display: flex;
    flex-direction: column;
    align-items: ${props => props.mobileLorR ? 'flex-start' : 'flex-end'};
`;

export const Icons = styled.a`
    margin-right: 5px;
    width: 100%;
`;

export const Icon = styled.img`
    height: 45px;
    width: 45px;
    :hover{
        cursor: pointer;
    }
`;

export const SearchIcon = styled.img`
    height: 20px;
    width: 20px;
    margin-left: 10px;
`;

export const Text = styled.p`
    font-size: 14px;
`;

export const Search = styled.div<StyleProps>`
    margin-left: auto;
    margin-right: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    opacity: ${props => props.isSearchPage ? "0%" : "100%"};
    pointer-events: ${props => props.isSearchPage ? "none" : "default"};

    @media only screen and (max-width:850px ) {
        margin-right: 0;
        width: 130px;
    }
`;

export const MainContainer = styled.div`
    width: 100%; 
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: solid 1px silver ;

    @media only screen and (max-width:850px ) {
        flex-direction: column;
        justify-content: center;
    }
`;

export const LogoImg = styled.img`
    display: flex;
    width: fit-content; 
    max-width: 150px;
`;

export const Brand = styled.a`
  margin-right: 25%;
  margin-left: 5%;
  @media only screen and (max-width:850px ) {
        margin:0;
    }
  
`;

export const MobileBrandWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
`

export const LinksList = styled.div`
    display: flex;
    width: 100%;
    padding-right: 5%;
    justify-content: space-between;

    @media only screen and (max-width:850px ) {
        padding-right: 0;
        padding-left: 10px;
        font-size: 12px;
        width: fit-content;
        flex-direction: column;
    }
`;

export const LinksListWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    @media only screen and (max-width:850px ) {
        text-align: center
    }
`
export const Links = styled.div`
        font-weight: bolder;
        color: #959698;
        padding-bottom: 5px;
    @media only screen and (max-width:850px ) {
        margin-left: 10px;
        padding-top: 10px;
    }
`;

export const LinkLink = styled(Link)`
text-decoration: none;
color: inherit;

&:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
    color: inherit;
        transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

`;

export const LinkText = styled.p` 
    margin: auto;
    position: relative;
  :hover{
    cursor: pointer;
    color: #1873b7;
    :after{
    border-right: solid 15px #1873b7;
    border-left: solid 15px  #1873b7;
    border-top: solid 5px #1873b7;
    transform: translateX(-50%);
    position: absolute;
    z-index: -1;
    content: "";
    top: 200%;
    left: 50%;
    height: 0;
    width: 0;
    
    }
  }
  
`;

export const MobileLinkText = styled.p` 
    margin: auto;
    position: relative;
  :hover{
    cursor: pointer;
    color: #1873b7;
  }
  
`;

export const ContactInfoInput = styled.input`
    width: 100%;
    height: 20px;
    border: solid 1px silver;
    border-radius: 3px;
    color: #1679bd;
    text-indent: 5px;
    background-color: transparent;
    font-size: 14px;
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
`

export const SearchLink = styled(Link)`
text-decoration: none;
color: inherit;
display: flex;

&:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
    color: inherit;
}
`;

export const DropdownList = styled.div`
  display: none;
  position: absolute;
  min-width: 160px;
  z-index: 5;
  margin-top: 5px;

  ${Links}:hover & {
    display: block;
    background-color: #fefffe;
    border: solid 1px silver;
  }
`;

export const LinkTextDrop = styled.p` 
    margin: 20px 10px;
  :hover{
    cursor: pointer;
    color: #1873b7;
  }
  
`;

