import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import facebook from "../assets/footer/fb - nocol.svg";
import twitter from "../assets/footer/twitter - nocol.svg";
import youtube from "../assets/footer/yt - nocol.svg";
import locationImg from "../assets/footer/footer-location-ico.svg";
import logo from "../assets/mipacs by apryse colour.svg";

import * as Styled from "./Styles/FooterStyles";
import { WindowWidthContext } from "../App";

const Footer = () => {
  const windowWidth = useContext(WindowWidthContext);
  const location = useLocation();
  const currentPath = location.pathname;

  if (windowWidth < 850) {
    return (
      <Styled.Footer>
        <Styled.TopContainer>
          <Styled.MobileBrandWrapper>
            <Styled.FooterBrand href="/">
              <Styled.LogoImg alt="MedicorImaging" src={logo} />
            </Styled.FooterBrand>
            <Styled.SocialsDiv>
              <Styled.SocialsImg
                alt="MedicorImaging Facebook"
                src={facebook}
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/MedicorImaging/",
                    "_blank"
                  )
                }
              />
              <Styled.SocialsImg
                alt="MedicorImaging Twitter"
                src={twitter}
                onClick={() =>
                  window.open(
                    "https://twitter.com/medicorimaging?lang=en",
                    "_blank"
                  )
                }
              />
              <Styled.SocialsImg
                alt="MedicorImaging YouTube"
                src={youtube}
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/channel/UC7PV-DPWmf_r-EnuV5aYhCA",
                    "_blank"
                  )
                }
              />
            </Styled.SocialsDiv>
          </Styled.MobileBrandWrapper>
        </Styled.TopContainer>

        <Styled.LineDiv />

        <Styled.BottomContainer>
          <Styled.MobileLinkListWrap>
            <Styled.FooterLinksList>
              <Styled.FooterLinks>
                <Styled.LinkLink to={"/"}>
                  <Styled.MobileLinkText>Home</Styled.MobileLinkText>
                </Styled.LinkLink>
              </Styled.FooterLinks>
              <Styled.FooterLinks>
                <Styled.LinkLink to={"/products"}>
                  <Styled.MobileLinkText>Products</Styled.MobileLinkText>
                </Styled.LinkLink>
              </Styled.FooterLinks>
            </Styled.FooterLinksList>

            <Styled.FooterLinksList>
              <Styled.FooterLinks>
                <Styled.LinkLink to={"/products/mipacs-demo-request"}>
                  <Styled.MobileLinkText>Evaluate</Styled.MobileLinkText>
                </Styled.LinkLink>
              </Styled.FooterLinks>
              <Styled.FooterLinks>
                <Styled.LinkLink to={"/support"}>
                  <Styled.MobileLinkText>Support</Styled.MobileLinkText>
                </Styled.LinkLink>
              </Styled.FooterLinks>
            </Styled.FooterLinksList>

            <Styled.FooterLinksList>
              <Styled.FooterLinks>
                <Styled.LinkLink to={"/contact"}>
                  <Styled.MobileLinkText>Contact</Styled.MobileLinkText>
                </Styled.LinkLink>
              </Styled.FooterLinks>
              <Styled.FooterLinks>
                <Styled.LinkLink to={"/corporate/privacy"}>
                  <Styled.MobileLinkText>Privacy</Styled.MobileLinkText>
                </Styled.LinkLink>
              </Styled.FooterLinks>
            </Styled.FooterLinksList>
          </Styled.MobileLinkListWrap>

          <Styled.LocationDiv>
            <Styled.LocationImg alt="MedicorImaging" src={locationImg} />
            <Styled.LocationText>
              1927 S TRYON STREET SUITE 200 <br></br>CHARLOTTE, NC 28203
            </Styled.LocationText>
          </Styled.LocationDiv>
          <Styled.Copyright>
            &copy; {new Date().getFullYear()} <Styled.LinkA href="https://www.apryse.com" target="_blank">Powered by Apryse: The Market Leader in Document Processing Technology </Styled.LinkA>• ALL RIGHTS RESERVED.
          </Styled.Copyright>
        </Styled.BottomContainer>
      </Styled.Footer>
    );
  }

  return (
    <Styled.Footer isSubPages={currentPath == "/" || currentPath == "/contact"}>
      <Styled.TopContainer>
        <Styled.FooterBrand href="/">
          <Styled.LogoImg alt="MedicorImaging" src={logo} />
        </Styled.FooterBrand>

        <Styled.FooterLinksList>
          <Styled.FooterLinks>
            <Styled.LinkLink to={"/"}>
              <Styled.FooterLinkText>Home</Styled.FooterLinkText>
            </Styled.LinkLink>
          </Styled.FooterLinks>

          <Styled.FooterLinks>
            <Styled.LinkLink to={"/products"}>
              <Styled.FooterLinkText>Products</Styled.FooterLinkText>
            </Styled.LinkLink>
          </Styled.FooterLinks>

          <Styled.FooterLinks>
            <Styled.LinkLink to={"/products/mipacs-demo-request"}>
              <Styled.FooterLinkText>Evaluate</Styled.FooterLinkText>
            </Styled.LinkLink>
          </Styled.FooterLinks>

          <Styled.FooterLinks>
            <Styled.LinkLink to={"/support"}>
              <Styled.FooterLinkText>Support</Styled.FooterLinkText>
            </Styled.LinkLink>
          </Styled.FooterLinks>

          <Styled.FooterLinks>
            <Styled.LinkLink to={"/contact"}>
              <Styled.FooterLinkText>Contact</Styled.FooterLinkText>
            </Styled.LinkLink>
          </Styled.FooterLinks>

          <Styled.FooterLinks>
            <Styled.LinkLink to={"/corporate/privacy"}>
              <Styled.FooterLinkText>Privacy</Styled.FooterLinkText>
            </Styled.LinkLink>
          </Styled.FooterLinks>
        </Styled.FooterLinksList>
      </Styled.TopContainer>

      <Styled.LineDiv />

      <Styled.BottomContainer>
        <Styled.LocationDiv>
          <Styled.LocationImg alt="MedicorImaging" src={locationImg} />
          <Styled.LocationText>
            1927 S TRYON STREET SUITE 200 <br></br>CHARLOTTE, NC 28203
          </Styled.LocationText>
        </Styled.LocationDiv>

        <Styled.Copyright>
            &copy; {new Date().getFullYear()} <Styled.LinkA href="https://www.apryse.com" target="_blank">Powered by Apryse: The Market Leader in Document Processing Technology </Styled.LinkA>• ALL RIGHTS RESERVED.
          </Styled.Copyright>
        <Styled.SocialsDiv>
          <Styled.SocialsImg
            alt="MedicorImaging Facebook"
            src={facebook}
            onClick={() =>
              window.open("https://www.facebook.com/MedicorImaging/", "_blank")
            }
          />
          <Styled.SocialsImg
            alt="MedicorImaging Twitter"
            src={twitter}
            onClick={() =>
              window.open(
                "https://twitter.com/medicorimaging?lang=en",
                "_blank"
              )
            }
          />
          <Styled.SocialsImg
            alt="MedicorImaging YouTube"
            src={youtube}
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UC7PV-DPWmf_r-EnuV5aYhCA",
                "_blank"
              )
            }
          />
        </Styled.SocialsDiv>
      </Styled.BottomContainer>
    </Styled.Footer>
  );
};

export default Footer;
