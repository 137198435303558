import styled from "styled-components";
import { StyleProps } from "../../Interfaces";


const MainImage = styled.div<StyleProps>`
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${(props) => props.image}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 60em;
  

  @media only screen and (max-width: 850px ) {
    height: 40em;
    width: 100%;
        }
`;

const MainContent = styled.div`
  text-align: center;
  color: white;
  height: 100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  @media only screen and (max-width: 850px ) {
    margin-top: 75px;
        }
`;

const AttentionGrabber=({ image, content }: any)=> {
  return (
    <MainImage image={image} >
      <MainContent>
        {content}
      </MainContent>
    </MainImage>
  );
}

export default AttentionGrabber;