import styled from "styled-components";
import { StyleProps } from '../../Interfaces';
import { Link } from "react-router-dom";

const MainDiv = styled.div<StyleProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50em;
    
    h1{
        width: 100%;
        text-align: center;
        margin: auto;
    }
`

const LoadingPage = () => {

    return (
        <MainDiv>
            <h1>Loading, please wait...</h1>
        </MainDiv>
    );
}

export default LoadingPage;