import React, { MouseEvent, useState, useContext, createRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as Styled from "./Styles/HomeStyles";

import AttentionGrabber from "./HomePageComponents/HomePageImage";
import ServicesBlock from "./HomePageComponents/HomePageServicesBlock";
import Testimonial from "./HomePageComponents/HomePageTestimonial"

import heroImage from "../assets/hero-image.png";
import phone from "../assets/home/home-page-phone.svg"
import arrow from "../assets/home/homepage-learn-more-arrow.svg";

import branches from "../assets/home/branches.svg";
import clinics from "../assets/home/clinics.svg";
import doctor from "../assets/home/doctor.svg";
import patients from "../assets/home/patients.svg";
import LEADTOOLS from "../assets/home/LEADTOOLS.svg";
import unlv from "../assets/home/unlv.png";
import unc from "../assets/home/unc.png";
import pds from "../assets/home/pds.png";

import { WindowWidthContext } from "../App";
import GetApiLocation from "../utils/GetApiLocation";

import ReCAPTCHA from "react-google-recaptcha";

const RecaptchaDiv = styled.div`
    margin: windowWidth < 8500px 5px;

`

const firstSee = [
    <>
        <Styled.ImageTextWrapper>
            {/* <Styled.ImageH3>Welcome to MiPACS</Styled.ImageH3> */}
            <Styled.ImageH1>SERVING THE GLOBAL DENTAL, ENT, AND RADIOLOGY INDUSTRIES WITH HIGH-QUALITY DICOM AND PACS SOLUTIONS</Styled.ImageH1>
            <Styled.ImageH2>Providing medical and dental professionals with the industry's best DICOM-compliant digital imaging solutions with MiPACS by Apryse</Styled.ImageH2>
        </Styled.ImageTextWrapper>
    </>
]

const Home = () => {

    const [email, setEmail] = useState<string>("");
    const windowWidth = useContext(WindowWidthContext);

    const recaptchaRef = createRef<ReCAPTCHA>();
    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

    const Recaptcha = (
        <RecaptchaDiv className="recaptcha">

            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={"6Lds3Y8mAAAAAFjVIFH3QNITfIE-tkPzmUwY4AH0"}
                onChange={(token) => setRecaptchaToken(token)}

            />
        </RecaptchaDiv>
    );

    const resetCaptcha = () => {
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
            setRecaptchaToken(null);
        }
    };

    const handleClick = async () => {

        if (recaptchaToken === null) {
            window.alert("Please fill out the reCAPTCHA");
            return;
        }

        document.body.style.cursor = 'wait';

        let checkRequest = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                StringInput: email,
            })
        };

        let checkResponse = await fetch(GetApiLocation() + 'api/Validate/EmailAddress', checkRequest);
        let checkData = await checkResponse;

        if (!checkData.ok) {
            window.alert("Please enter a valid email adress")
            document.body.style.cursor = 'default';
            return;
        }


        let request = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                Email: email,
                IsSubscribed: true,
                CaptchaToken: recaptchaToken,
            })
        };

        let response = await fetch(GetApiLocation() + "medicor/setsubscriptionstatus", request);
        let data = await response;
        if (data.ok) {
            // window.gtag('event', 'subscription', {
            //     status: "subscribed"
            // });
            (window as any).dataLayer.push({
                'event': 'emailStatus',
                'status': 'subscribed'
            });
            document.body.style.cursor = 'default';
            window.alert("You are now subscribed to the MiPACS Newsletter!");
            setEmail("");
            (document.getElementById("signUpHome") as HTMLInputElement).value = '';
        }
        else {
            resetCaptcha();
            document.body.style.cursor = 'default';
            window.alert("Something happened on our side, if this problem persists please go to the contact page and email our support team for help!");
        }
    }

    if (windowWidth < 850) {
        return (
            <Styled.MainDiv>
                <AttentionGrabber image={heroImage} content={firstSee} />
                <Styled.ServiceBlocks >
                    <Styled.ServiceBlock
                        bColor="#015dbc"
                        onClick={() => window.location.href = "/products/mipacs-storage-server"}
                    >MIPACS STORAGE SERVER
                    </Styled.ServiceBlock>
                    <Styled.ServiceBlock
                        bColor="#1c6bba"
                        onClick={() => window.location.href = "/products/mipacs-html5-web-viewer"}
                    >MIPACS HTML5 WEB VIEWER
                    </Styled.ServiceBlock>
                    <Styled.ServiceBlock
                        bColor="#2477cc"
                        onClick={() => window.location.href = "/products/mipacs-dental-enterprise-viewer"}
                    >MIPACS DENTAL ENTERPRISE VIEWER
                    </Styled.ServiceBlock>

                </Styled.ServiceBlocks>

                <Styled.ServiceBlocks>
                    <Styled.ServiceBlock
                        bColor="#2d84e1"
                        onClick={() => window.location.href = "/products"}
                    >PRODUCT BUNDLES
                    </Styled.ServiceBlock>
                    <Styled.ServiceBlock
                        bColor="#4e9dec"
                        onClick={() => window.location.href = "/products/mipacs-demo-request"}
                    >REQUEST EVALUATION
                    </Styled.ServiceBlock>
                    <Styled.ServiceBlock
                        bColor="#5ba2ee"
                        onClick={() => window.open('https://www.leadtools.com/sdk/engine/medical', '_blank')}
                    >LEADTOOLS MEDICAL IMAGING SDKS
                    </Styled.ServiceBlock>
                </Styled.ServiceBlocks>

                <Styled.ServiceSquareLittleMoreWrapper id="little">
                    <Styled.ServiceSquareLittleMoreH2>Here is a little more about our products and services</Styled.ServiceSquareLittleMoreH2>
                    <Styled.ImageButtonWrapper>
                        <Styled.ServiceSquareLittleMoreLink href="/products">Learn More</Styled.ServiceSquareLittleMoreLink>
                        <Styled.LearnMoreImg onClick={() => window.location.href = "/products"} src={arrow} alt="Arrow pointing to learn more about Medicor Imaging Products" />
                    </Styled.ImageButtonWrapper>

                </Styled.ServiceSquareLittleMoreWrapper>

                <ServicesBlock />

                <Styled.ContactBlurb>
                    <Styled.ContactBlurbImg src={phone} alt="Call MedicorImaging" />
                    <Styled.ContactBlurbText>Schedule a demo or call us at: +1(704)277-2629 </Styled.ContactBlurbText>
                </Styled.ContactBlurb>

                <Styled.StatBlocksWrapperMobile>
                    <Styled.StatBlocks>
                        <Styled.StatBlock
                            bColor="#feffff"
                            bShadow="#80808038 0px 2.25em 4.6em"
                        >
                            <Styled.StatBlockImg src={doctor} alt="Doctor Stat Block" />
                            <h3>75+</h3>
                            <p>Dental Schools</p>

                        </Styled.StatBlock>
                        <Styled.StatBlock
                            bColor="#feffff"
                            bShadow="#80808038 0px 2.25em 4.6em"
                        >
                            <Styled.StatBlockImg src={patients} alt="Patient Stat Block" />
                            <h3>2000+</h3>
                            <p>DSO Clinics</p>
                        </Styled.StatBlock>
                    </Styled.StatBlocks>
                    <Styled.StatBlocks>
                        <Styled.StatBlock
                            bColor="#feffff"
                            bShadow="#80808038 0px 2.25em 4.6em"
                        >
                            <Styled.StatBlockImg src={clinics} alt="Clinic Stat Block" />
                            <h3>140+</h3>
                            <p>VA Clinics</p>
                        </Styled.StatBlock>
                        <Styled.StatBlock
                            bColor="#feffff"
                            bShadow="#80808038 0px 2.25em 4.6em"
                        >
                            <Styled.StatBlockImg src={branches} alt="Branch Stat Block" />
                            <h3>75+</h3>
                            <p>Health Centers</p>
                        </Styled.StatBlock>
                    </Styled.StatBlocks>
                </Styled.StatBlocksWrapperMobile>

                <Styled.StatBlocksWrapper>

                    <Styled.TestimonialWrapper>

                        <Testimonial
                            image={unlv}
                            textName="Pat Ferrillo"
                            textJob={<>Dean of UNLV School of Dental Medicine</>}
                            textDesc="&ldquo;With a vision to provide the best dental care and a goal to become a paperless and digital
                             dental school, we were thoroughly impressed with the professionalism that was shown by Medicor Imaging 
                             in accomplishing and reaching the goals that were set forth by this university.&rdquo;"

                        />
                        <Testimonial
                            image={unc}
                            textName="Dr. Don Tyndall"
                            textJob="Director of Radiology at the UNC School of Dentistry"
                            textDesc="&ldquo;MiPACS software provides the UNC School of Dentistry with a significant pathway for growth in digital 2-D and 3-D technology.&rdquo;"

                        />

                        <Testimonial
                            image={pds}
                            textName="David Baker"
                            textJob="SVP Chief Information and Digital Officer at Pacific Dental Services"
                            textDesc="&ldquo;We selected MiPACS as the anchor solution for Pacific Dental Services 1VU, our modern-day approach to dental image management. With 800+ offices, an enterprise focused imaging solution was a must-have. MiPACS integrates seamlessly with our Practice Management System EPIC, supports the sensor, panoramic and CBCT devices we use, and includes a Zero-Footprint web viewer. It gave us confidence that MiPACS is built using LEADTOOLS Medical Developer Toolkit and is powered by technology that would solve our current and future needs.&rdquo;"

                        />
                    </Styled.TestimonialWrapper>
                </Styled.StatBlocksWrapper>
                <Styled.PoweredBy>
                    <Styled.PoweredByWrapper>
                        <h1>MiPACS is powered by </h1>
                        <Styled.PoweredByImg src={LEADTOOLS} alt="Powered By LEADTOOLS Medical Imaging SDK" onClick={() => window.open('https://www.leadtools.com', '_blank')} />
                    </Styled.PoweredByWrapper>
                    <p>
                        MiPACS is developed with the award-winning <a href="https://www.leadtools.com/sdk/engine/medical">LEADTOOLS Medical Imaging SDKs.</a><br /> If you are a software developer, or interested in learning more,
                        <br /> please visit LEADTOOLS.
                    </p>
                </Styled.PoweredBy>

                <Styled.SignUpWrapper>
                    <Styled.SignUpDiv>
                        <Styled.SignUpText>Subscribe to the MiPACS<br></br>Newsletter</Styled.SignUpText>
                        <Styled.SignUpInput id="signUpHome" placeholder="Enter Your Email" onChange={(e) => setEmail(e.target.value)}></Styled.SignUpInput>
                        {Recaptcha}
                        <Styled.SignUpButton onClick={handleClick}>Register</Styled.SignUpButton>
                    </Styled.SignUpDiv>
                </Styled.SignUpWrapper>

            </Styled.MainDiv>
        );
    }

    return (
        <Styled.MainDiv>
            <AttentionGrabber image={heroImage} content={firstSee} />
            <Styled.ServiceBlocks >
                <Styled.ServiceBlock
                    bColor="#015dbc"
                    onClick={() => window.location.href = "/products/mipacs-storage-server"}
                >MIPACS STORAGE SERVER
                </Styled.ServiceBlock>
                <Styled.ServiceBlock
                    bColor="#1c6bba"
                    onClick={() => window.location.href = "/products/mipacs-html5-web-viewer"}
                >MIPACS HTML5 WEB VIEWER
                </Styled.ServiceBlock>
                <Styled.ServiceBlock
                    bColor="#2477cc"
                    onClick={() => window.location.href = "/products/mipacs-dental-enterprise-viewer"}
                >MIPACS DENTAL ENTERPRISE VIEWER
                </Styled.ServiceBlock>
                <Styled.ServiceBlock
                    bColor="#2d84e1"
                    onClick={() => window.location.href = "/products"}
                >PRODUCT BUNDLES
                </Styled.ServiceBlock>
                <Styled.ServiceBlock
                    bColor="#4e9dec"
                    onClick={() => window.location.href = "/products/mipacs-demo-request"}
                >REQUEST EVALUATION
                </Styled.ServiceBlock>
                <Styled.ServiceBlock
                    bColor="#5ba2ee"
                    onClick={() => window.open('https://www.leadtools.com/sdk/engine/medical', '_blank')}
                >LEADTOOLS MEDICAL IMAGING SDKS
                </Styled.ServiceBlock>
            </Styled.ServiceBlocks>

            <Styled.ServiceSquareLittleMoreWrapper id="little">
                <Styled.ServiceSquareLittleMoreH2>Here is a little more about our products and services</Styled.ServiceSquareLittleMoreH2>
                <Styled.ImageButtonWrapper>
                    <Styled.ServiceSquareLittleMoreLink href="/products">Learn More</Styled.ServiceSquareLittleMoreLink>
                    <Styled.LearnMoreImg onClick={() => window.location.href = "/products"} src={arrow} alt="Arrow pointing to learn more about Medicor Imaging Products" />
                </Styled.ImageButtonWrapper>

            </Styled.ServiceSquareLittleMoreWrapper>

            <ServicesBlock />

            <Styled.ContactBlurb>
                <Styled.ContactBlurbImg src={phone} alt="Call Medicor Imaging" />
                <Styled.ContactBlurbText>Schedule a demo or call us at: +1(704)277-2629 </Styled.ContactBlurbText>
            </Styled.ContactBlurb>

            <Styled.StatBlocksWrapper>
                <Styled.StatBlocks>
                    <Styled.StatBlock
                        bColor="#feffff"
                        bShadow="#80808038 0px 2.25em 4.6em"
                    >
                        <Styled.ContactBlurbImg src={doctor} alt="Doctor Stat Block" />
                        <h3>75+</h3>
                        <p>Dental Schools</p>

                    </Styled.StatBlock>
                    <Styled.StatBlock
                        bColor="#feffff"
                        bShadow="#80808038 0px 2.25em 4.6em"
                    >
                        <Styled.ContactBlurbImg src={patients} alt="Patient Stat Block" />
                        <h3>2000+</h3>
                        <p>DSO Clinics</p>
                    </Styled.StatBlock>
                    <Styled.StatBlock
                        bColor="#feffff"
                        bShadow="#80808038 0px 2.25em 4.6em"
                    >
                        <Styled.ContactBlurbImg src={clinics} alt="Clinic Stat Block" />
                        <h3>140+</h3>
                        <p>VA Clinics</p>
                    </Styled.StatBlock>
                    <Styled.StatBlock
                        bColor="#feffff"
                        bShadow="#80808038 0px 2.25em 4.6em"
                    >
                        <Styled.ContactBlurbImg src={branches} alt="Branch Stat Block" />
                        <h3>75+</h3>
                        <p>Health Centers</p>
                    </Styled.StatBlock>
                </Styled.StatBlocks>
                <Styled.TestimonialWrapper>

                    <Testimonial
                        image={unlv}
                        textName="Pat Ferrillo"
                        textJob={<>Dean of UNLV School of Dental Medicine</>}
                        textDesc="&ldquo;With a vision to provide the best dental care and a goal to become a paperless and digital
                         dental school, we were thoroughly impressed with the proefessionalism that was shown by Medicor Imaging 
                         in accomplishing and reaching the goals that were set forth by this university.&rdquo;"

                    />
                    <Testimonial
                        image={unc}
                        textName="Dr. Don Tyndall"
                        textJob="Director of Radiology at the UNC School of Dentistry"
                        textDesc="&ldquo;MiPACS software provides the UNC School of Dentistry with a significant pathway for growth in digital 2-D and 3-D technology.&rdquo;"

                    />

                    <Testimonial
                        image={pds}
                        textName="David Baker"
                        textJob="SVP Chief Information and Digital Officer at Pacific Dental Services"
                        textDesc="&ldquo;We selected MiPACS as the anchor solution for Pacific Dental Services 1VU, our modern-day approach to dental image management. With 800+ offices, an enterprise focused imaging solution was a must-have. MiPACS integrates seamlessly with our Practice Management System EPIC, supports the sensor, panoramic and CBCT devices we use, and includes a Zero-Footprint web viewer. It gave us confidence that MiPACS is built using LEADTOOLS Medical Developer Toolkit and is powered by technology that would solve our current and future needs.&rdquo;"

                    />
                </Styled.TestimonialWrapper>
            </Styled.StatBlocksWrapper>
            <Styled.PoweredBy>
                <Styled.PoweredByWrapper>
                    <h1>MiPACS is powered by </h1>
                    <Styled.PoweredByImg src={LEADTOOLS} alt="Powered By LEADTOOLS Medical Imaging SDK" onClick={() => window.open('https://www.leadtools.com', '_blank')} />
                </Styled.PoweredByWrapper>
                <p>
                    MiPACS is developed with the award-winning <a href="https://www.leadtools.com/sdk/engine/medical">LEADTOOLS Medical Imaging SDKs.</a><br /> If you are a software developer, or interested in learning more,
                    <br /> please visit LEADTOOLS.
                </p>
            </Styled.PoweredBy>
            <Styled.SignUpWrapper>
                <Styled.SignUpDiv>
                    <Styled.SignUpText>Subscribe to the MiPACS <br></br>Newsletter</Styled.SignUpText>
                    <Styled.SignUpInput id="signUpHome" placeholder="Enter Your Email" onChange={(e) => setEmail(e.target.value)}></Styled.SignUpInput>
                    {Recaptcha}
                    <Styled.SignUpButton onClick={handleClick}>Register</Styled.SignUpButton>
                </Styled.SignUpDiv>
            </Styled.SignUpWrapper>
        </Styled.MainDiv>
    );

};

export default Home;
