import styled from "styled-components";
import { useState } from 'react';
import { StyleProps } from "../../Interfaces";
import ExpandingSquare from "../GlobalComponents/ExpandingSquare";

import tempImage from "../../assets/favicon-96x96.png"
import dentalSolution from "../../assets/home/dental-solution.svg";
import dentalSolutionRoll from "../../assets/home/dental-solution-roll.svg";
import dentalViewer from "../../assets/home/dental-viewer.svg";
import dentalViewerRoll from "../../assets/home/dental-viewer-roll.svg";
import medicalImaging from "../../assets/home/medical-imaging.svg";
import medicalImagingRoll from "../../assets/home/medical-imaging-roll.svg";
import pacs from "../../assets/home/pacs.svg";
import pacsRoll from "../../assets/home/pacs-roll.svg";
import storageServer from "../../assets/home/storage-server.svg";
import storageServerRoll from "../../assets/home/storage-server-roll.svg";
import webviewer from "../../assets/home/web-viewer.svg";
import webviewerRoll from "../../assets/home/web-viewer-roll.svg";


import { useEffect } from "react";

const MainDiv = styled.div`
    display: flex;
    flex-direction: row;
    width: 80%;
    height: 900px;
    margin: 3% auto;

    @media only screen and (max-width: 1450px ) {
        flex-direction: column;
        height: auto;
    }
`;

const MainColumns = styled.div<StyleProps>`
    transform: translateY(${props => props.serviceSquareLocation});
    display: flex;
    flex-direction: column;
    width: 100%;

    

    &.active{
            & div:first-child{
                background-color: #272d48;
                color: #feffff;
                cursor: pointer;
                button{
                    background-color: #feffff;
                    color: #272d48;
                    cursor: pointer;
                }
            }
        }
    @media only screen and (max-width:1450px ) {
        transform: translateY(0%);
    }
`;

const HomePageServicesBlock = () => {

    const [isActive, setIsActive] = useState(true);

    function handleMouseOver() {
        setIsActive(false);
    }

    return (
        <MainDiv>
            <MainColumns serviceSquareLocation="-23%" onMouseOver={handleMouseOver}>
                <ExpandingSquare
                    textT="MIPACS STORAGE SERVER"
                    expandSquarebuttonLink="/products/mipacs-storage-server"
                    image={storageServerRoll}
                    imageRoll={storageServer}
                    textB="The MiPACS Storage Server is a fast, user-friendly, scalable, modular, and affordable Picture Archive and Communication System (PACS). Powered by the industry-leading medical imaging engine from LEADTOOLS, it serves as both the core of a PACS and a centralized repository for radiology and medical DICOM images and objects."
                    isActive={isActive}
                />
                <ExpandingSquare
                    textT="MIPACS DENTAL ENTERPRISE SOLUTION"
                    expandSquarebuttonLink="/products/mipacs-dental-enterprise-solution"
                    image={dentalSolutionRoll}
                    imageRoll={dentalSolution}
                    textB="Built in accordance with the DICOM 3.0 standard, MiPACS offers a scalable, device-independent, and affordable solution that was once out of reach for the dental industry. Consisting of two pieces—the MiPACS Storage Server and the MiPACS Dental Enterprise Viewer—it is a complete dental PACS."
                />
            </MainColumns>
            <MainColumns serviceSquareLocation="0%" onMouseOver={handleMouseOver}>
                <ExpandingSquare
                    textT="MIPACS HTML5 WEB VIEWER"
                    expandSquarebuttonLink="/products/mipacs-html5-web-viewer"
                    image={webviewerRoll}
                    imageRoll={webviewer}
                    textB="Built on the foundation of the LEADTOOLS PACS Imaging SDK, the MiPACS HTML5 Web Viewer directly integrates with the MiPACS Storage Server to provide a zero-footprint medical viewer that can be accessed using any modern web browser. The MiPACS HTML5 Web Viewer requires no installation on clients and is compatible with any HTML5-compliant web browser."
                />
                <ExpandingSquare
                    textT="Live Online Demo for HTML5 Web Viewer"
                    expandSquarebuttonLink="https://demo.leadtools.com/MiMedicalViewer"
                    image={pacsRoll}
                    imageRoll={pacs}
                    textB="Try for yourself and check-out our zero-footprint HTML5 Web Viewer with robust features including Endo, Perio, Dentin, and FMX layouts. "
                />
            </MainColumns>
            <MainColumns serviceSquareLocation="23%" onMouseOver={handleMouseOver}>
                <ExpandingSquare
                    textT="MIPACS DENTAL ENTERPRISE VIEWER"
                    expandSquarebuttonLink="/products/mipacs-dental-enterprise-viewer"
                    image={dentalViewerRoll}
                    imageRoll={dentalViewer}
                    textB="MiPACS Dental Enterprise Viewer is the only application necessary for capturing and viewing images. Its open architecture allows you to have the freedom to use any capture devices, while maintaining compatibility with industry standards. Find out below what makes MiPACS Dental Enterprise Viewer the ideal image capture and viewing software for any large dental organization."
                />
                <ExpandingSquare
                    textT="LEADTOOLS MEDICAL IMAGING SDKS"
                    expandSquarebuttonLink="https://www.leadtools.com/sdk/engine/medical"
                    image={medicalImagingRoll}
                    imageRoll={medicalImaging}
                    textB="Develop powerful medical imaging applications with the LEADTOOLS Medical Imaging SDK. LEADTOOLS Medical libraries—particularly LEAD's DICOM libraries—are widely distributed throughout a variety of medical and health care markets."
                />
            </MainColumns>
        </MainDiv>
    );
}

export default HomePageServicesBlock;