import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import image from "../assets/mipacs by apryse colour.svg";
import { NavSearchContext } from "../App";
import { Link, useLocation } from 'react-router-dom';

import searchIcon from "../assets/search.svg"
import fb from "../assets/fb - nocir.svg"
import twitter from "../assets/twitter - nocir.svg"
import yt from "../assets/yt - nocir.svg"

import fbRoll from "../assets/fb - nocir - roll.svg"
import twitterRoll from "../assets/twitter - nocir - roll.svg"
import ytRoll from "../assets/yt - nocir - roll.svg"


import * as Styled from "./Styles/NavbarStyles";

import { WindowWidthContext } from "../App";
import DropDownSelection from "./GlobalComponents/DropDownSelction";

export const HeaderDiv = styled.div`
.navbar{
    /* transition:all .2s ease; */
    /* padding-top: 10px;
    padding-bottom: 10px; */
    background-color: #feffff;
}
.navbar-toggler{
  background-color: rgba(255,255,255,.4);
    border-color: rgba(255,255,255,.4);
}
.scrolled{
    opacity: 0;
    pointer-events: none;
  
}
`;
// background-color: #feffff;
//   opacity: .7;
//     /* padding-top: 30px;
//     padding-bottom: 30px; */
//     :hover{
//         opacity: 1;
//     }
const Navbar = () => {
    const { navSearch, setNavSearch } = useContext<any>(NavSearchContext);
    const [scrolled, setScrolled] = useState(false);
    const [lastScroll, setLastScroll] = useState(0);
    const [hovered, setHovered] = useState(false);

    const windowWidth = useContext(WindowWidthContext);
    const location = useLocation();
    const currentPath = location.pathname;

    const [fbImg, setfbImg] = useState<string>(fb);
    const [twitterImg, settwitterImg] = useState<string>(twitter);
    const [ytImg, setytImg] = useState<string>(yt);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > lastScroll) {
            setScrolled(true);
        }
        else {
            setScrolled(false);
        }
        setLastScroll(offset);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    })

    let x = ['navbar'];
    if (scrolled) {
        x.push('scrolled');
    }

    if (windowWidth < 850) {
        return (
            <HeaderDiv>
                <Styled.Navbar className={x.join(" ")}>
                    <Styled.MainContainer>
                        <Styled.MobileBrandWrapper>
                            <Styled.MobileIconTextWrapper mobileLorR={true}>
                                <Styled.Brand href="/">
                                    <Styled.LogoImg
                                        alt="MedicorImaging"
                                        src={image}
                                    />
                                </Styled.Brand>
                                <Styled.IconPack>
                                    <Styled.Icon src={fbImg} alt="Medicor Imaging's Facebook" onMouseEnter={() => setfbImg(fbRoll)} onMouseLeave={() => setfbImg(fb)} onClick={() => window.open('https://www.facebook.com/MedicorImaging/', '_blank')} />
                                    <Styled.Icon src={twitterImg} alt="Medicor Imaging's Twitter" onMouseEnter={() => settwitterImg(twitterRoll)} onMouseLeave={() => settwitterImg(twitter)} onClick={() => window.open('https://twitter.com/medicorimaging?lang=en', '_blank')} />
                                    <Styled.Icon src={ytImg} alt="Medicor Imaging's YouTube" onMouseEnter={() => setytImg(ytRoll)} onMouseLeave={() => setytImg(yt)} onClick={() => window.open('https://www.youtube.com/channel/UC7PV-DPWmf_r-EnuV5aYhCA', '_blank')} />
                                    {/* <Styled.Icon src={gplus} /> */}
                                </Styled.IconPack>
                            </Styled.MobileIconTextWrapper>
                            <Styled.MobileIconTextWrapper mobileLorR={false}>


                                <Styled.Text>
                                    <Styled.LinkLink to={'/products/mipacs-demo-request'}>
                                        <b>Schedule a Demo</b>
                                    </Styled.LinkLink>
                                </Styled.Text>
                                <Styled.Search isSearchPage={currentPath == '/search'}>
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        document.getElementById('searchIcon')?.click();
                                    }}>
                                        <Styled.ContactInfoInput
                                            type="text"
                                            value={navSearch}
                                            placeholder="Search..."
                                            onChange={(e) => setNavSearch(e.target.value)}
                                        />
                                    </form>
                                    {/* <Styled.SearchIcon src={searchIcon} /> */}
                                    <Styled.SearchLink id="searchIcon" to={"/search"} />

                                </Styled.Search>
                            </Styled.MobileIconTextWrapper>
                        </Styled.MobileBrandWrapper>
                        <Styled.LinksListWrapper>

                            <Styled.LinksList>
                                <Styled.Links>
                                    <Styled.LinkLink to={'/industry'}>
                                        <Styled.MobileLinkText>Industry Solutions</Styled.MobileLinkText>
                                    </Styled.LinkLink>
                                    <Styled.DropdownList>
                                        <Styled.LinkLink to={'/industry/dental-schools'}>
                                            <Styled.LinkTextDrop>Dental Schools</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                        <Styled.LinkLink to={'/industry/federally-qualified-health-centers'}>
                                            <Styled.LinkTextDrop>Federally Qualified Health Centers</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                        <Styled.LinkLink to={'/industry/government-agencies'}>
                                            <Styled.LinkTextDrop>Government Agencies</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                        <Styled.LinkLink to={'/industry/medical-imaging-centers'}>
                                            <Styled.LinkTextDrop>Medical Imaging Centers</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                        <Styled.LinkLink to={'/industry/multi-clinic-dental-organizations'}>
                                            <Styled.LinkTextDrop>Multi-Clinic Dental Organizations</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                    </Styled.DropdownList>
                                </Styled.Links>
                                <Styled.Links>
                                    <Styled.LinkLink to={'/products'}>
                                        <Styled.MobileLinkText>Products</Styled.MobileLinkText>
                                    </Styled.LinkLink>
                                    <Styled.DropdownList>
                                        <Styled.LinkLink to={'/products/mipacs-storage-server'}>
                                            <Styled.LinkTextDrop>MiPACS Storage Server</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                        <Styled.LinkLink to={'/products/mipacs-dicom-router'}>
                                            <Styled.LinkTextDrop>MiPACS DICOM Router+</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                        <Styled.LinkLink to={'/products/mipacs-hl7-addon'}>
                                            <Styled.LinkTextDrop>MiPACS HL7</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                        <Styled.LinkLink to={'/products/mipacs-modality-worklist'}>
                                            <Styled.LinkTextDrop>MiPACS Modality Worklist</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                        <Styled.LinkLink to={'/products/mipacs-html5-web-viewer'}>
                                            <Styled.LinkTextDrop>MiPACS HTML5 Web Viewer</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                        <Styled.LinkLink to={'/products/mipacs-dental-enterprise-viewer'}>
                                            <Styled.LinkTextDrop>MiPACS Dental Enterprise Viewer</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                        <Styled.LinkLink to={'/products/mipacs-dental-enterprise-solution'}>
                                            <Styled.LinkTextDrop>MiPACS Dental Enterprise Solution</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                        <Styled.LinkLink to={'/products/compatibility'}>
                                            <Styled.LinkTextDrop>Device Compatibility</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                    </Styled.DropdownList>
                                </Styled.Links>

                            </Styled.LinksList>
                            <Styled.LinksList>
                                <Styled.Links>
                                    <Styled.LinkLink to={'/support'}>
                                        <Styled.MobileLinkText>Support</Styled.MobileLinkText>
                                    </Styled.LinkLink>
                                </Styled.Links>
                                <Styled.Links>
                                    <Styled.LinkLink to={'/corporate'}>
                                        <Styled.MobileLinkText>Corporate</Styled.MobileLinkText>
                                    </Styled.LinkLink>
                                    <Styled.DropdownList>
                                        <Styled.LinkLink to={'/corporate/about'}>
                                            <Styled.LinkTextDrop>About</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                        <Styled.LinkLink to={'/corporate/careers'}>
                                            <Styled.LinkTextDrop>Careers</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                        <Styled.LinkLink to={'/corporate/case-studies'}>
                                            <Styled.LinkTextDrop>Case Studies</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                        <Styled.LinkLink to={'/corporate/events'}>
                                            <Styled.LinkTextDrop>Events</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                        <Styled.LinkLink to={'/corporate/references'}>
                                            <Styled.LinkTextDrop>References</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                        <Styled.LinkLink to={'/corporate/pressroom'}>
                                            <Styled.LinkTextDrop>Press Room</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                    </Styled.DropdownList>
                                </Styled.Links>
                            </Styled.LinksList>

                            <Styled.LinksList>

                                <Styled.Links>
                                    <Styled.LinkLink to={'/contact'}>
                                        <Styled.MobileLinkText>Contact</Styled.MobileLinkText>
                                    </Styled.LinkLink>
                                    <Styled.DropdownList>
                                        <Styled.LinkLink to={'/contact/subscription'}>
                                            <Styled.LinkTextDrop>Email Subscription</Styled.LinkTextDrop>
                                        </Styled.LinkLink>
                                    </Styled.DropdownList>
                                </Styled.Links>
                                <Styled.Links>
                                    <Styled.LinkLink to={'/mipacs-user-meeting'}>
                                        <Styled.MobileLinkText>MiPACS User Meeting</Styled.MobileLinkText>
                                    </Styled.LinkLink>
                                </Styled.Links>
                            </Styled.LinksList>

                        </Styled.LinksListWrapper>

                    </Styled.MainContainer>
                </Styled.Navbar>
            </HeaderDiv >
        );
    }


    return (
        <HeaderDiv>
            <Styled.Navbar className={x.join(" ")}>
                <Styled.TopContainer>
                    <Styled.IconPack>
                        <Styled.Icon src={fbImg} alt="Medicor Imaging's Facebook" onMouseEnter={() => setfbImg(fbRoll)} onMouseLeave={() => setfbImg(fb)} onClick={() => window.open('https://www.facebook.com/MedicorImaging/', '_blank')} />
                        <Styled.Icon src={twitterImg} alt="Medicor Imaging's Twitter" onMouseEnter={() => settwitterImg(twitterRoll)} onMouseLeave={() => settwitterImg(twitter)} onClick={() => window.open('https://twitter.com/medicorimaging?lang=en', '_blank')} />
                        <Styled.Icon src={ytImg} alt="Medicor Imaging's YouTube" onMouseEnter={() => setytImg(ytRoll)} onMouseLeave={() => setytImg(yt)} onClick={() => window.open('https://www.youtube.com/channel/UC7PV-DPWmf_r-EnuV5aYhCA', '_blank')} />
                        {/* <Styled.Icon src={gplus} /> */}
                    </Styled.IconPack>
                    <Styled.Text>
                        <Styled.LinkLink to={'/products/mipacs-demo-request'}>
                            <b>Schedule a Demo</b>
                        </Styled.LinkLink>


                    </Styled.Text>
                    <Styled.Search isSearchPage={currentPath === '/search'}>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            document.getElementById('searchIcon')?.click();
                        }}>
                            <Styled.ContactInfoInput
                                type="text"
                                value={navSearch}
                                placeholder="Search..."
                                onChange={(e) => setNavSearch(e.target.value)}
                            />
                        </form>
                        {/* <Styled.SearchIcon src={searchIcon} /> */}
                        <Styled.SearchLink id="searchIcon" to={"/search"} />

                    </Styled.Search>
                </Styled.TopContainer>
                <Styled.MainContainer>
                    <Styled.Brand href="/">
                        <Styled.LogoImg
                            alt="Medicor Imaging"
                            src={image}
                        />
                    </Styled.Brand>
                    <Styled.LinksList>
                        <Styled.Links>
                            <Styled.LinkLink to={'/industry'}>
                                <Styled.LinkText>Industry Solutions</Styled.LinkText>
                            </Styled.LinkLink>
                            <Styled.DropdownList>
                                <Styled.LinkLink to={'/industry/dental-schools'}>
                                    <Styled.LinkTextDrop>Dental Schools</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                                <Styled.LinkLink to={'/industry/federally-qualified-health-centers'}>
                                    <Styled.LinkTextDrop>Federally Qualified Health Centers</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                                <Styled.LinkLink to={'/industry/government-agencies'}>
                                    <Styled.LinkTextDrop>Government Agencies</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                                <Styled.LinkLink to={'/industry/medical-imaging-centers'}>
                                    <Styled.LinkTextDrop>Medical Imaging Centers</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                                <Styled.LinkLink to={'/industry/multi-clinic-dental-organizations'}>
                                    <Styled.LinkTextDrop>Multi-Clinic Dental Organizations</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                            </Styled.DropdownList>
                        </Styled.Links>
                        <Styled.Links>
                            <Styled.LinkLink to={'/products'}>
                                <Styled.LinkText>Products</Styled.LinkText>
                            </Styled.LinkLink>
                            <Styled.DropdownList>
                                <Styled.LinkLink to={'/products/mipacs-storage-server'}>
                                    <Styled.LinkTextDrop>MiPACS Storage Server</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                                <Styled.LinkLink to={'/products/mipacs-dicom-router'}>
                                    <Styled.LinkTextDrop>MiPACS DICOM Router+</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                                <Styled.LinkLink to={'/products/mipacs-hl7-addon'}>
                                    <Styled.LinkTextDrop>MiPACS HL7</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                                <Styled.LinkLink to={'/products/mipacs-modality-worklist'}>
                                    <Styled.LinkTextDrop>MiPACS Modality Worklist</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                                <Styled.LinkLink to={'/products/mipacs-html5-web-viewer'}>
                                    <Styled.LinkTextDrop>MiPACS HTML5 Web Viewer</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                                <Styled.LinkLink to={'/products/mipacs-dental-enterprise-viewer'}>
                                    <Styled.LinkTextDrop>MiPACS Dental Enterprise Viewer</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                                <Styled.LinkLink to={'/products/mipacs-dental-enterprise-solution'}>
                                    <Styled.LinkTextDrop>MiPACS Dental Enterprise Solution</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                                <Styled.LinkLink to={'/products/compatibility'}>
                                    <Styled.LinkTextDrop>Device Compatibility</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                            </Styled.DropdownList>
                        </Styled.Links>

                        <Styled.Links>
                            <Styled.LinkLink to={'/support'}>
                                <Styled.LinkText>Support</Styled.LinkText>
                            </Styled.LinkLink>
                        </Styled.Links>
                        <Styled.Links>
                            <Styled.LinkLink to={'/corporate'}>
                                <Styled.LinkText>Corporate</Styled.LinkText>
                            </Styled.LinkLink>
                            <Styled.DropdownList>
                                <Styled.LinkLink to={'/corporate/about'}>
                                    <Styled.LinkTextDrop>About</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                                <Styled.LinkLink to={'/corporate/careers'}>
                                    <Styled.LinkTextDrop>Careers</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                                <Styled.LinkLink to={'/corporate/case-studies'}>
                                    <Styled.LinkTextDrop>Case Studies</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                                <Styled.LinkLink to={'/corporate/events'}>
                                    <Styled.LinkTextDrop>Events</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                                <Styled.LinkLink to={'/corporate/references'}>
                                    <Styled.LinkTextDrop>References</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                                <Styled.LinkLink to={'/corporate/pressroom'}>
                                    <Styled.LinkTextDrop>Press Room</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                            </Styled.DropdownList>
                        </Styled.Links>
                        <Styled.Links>
                            <Styled.LinkLink to={'/contact'}>
                                <Styled.LinkText>Contact</Styled.LinkText>
                            </Styled.LinkLink>
                            <Styled.DropdownList>
                                <Styled.LinkLink to={'/contact/subscription'}>
                                    <Styled.LinkTextDrop>Email Subscription</Styled.LinkTextDrop>
                                </Styled.LinkLink>
                            </Styled.DropdownList>
                        </Styled.Links>
                        <Styled.Links>
                            <Styled.LinkLink to={'/mipacs-user-meeting'}>
                                <Styled.LinkText>MiPACS User Meeting</Styled.LinkText>
                            </Styled.LinkLink>
                        </Styled.Links>

                    </Styled.LinksList>
                </Styled.MainContainer>
            </Styled.Navbar>
        </HeaderDiv >
    );
}

export default Navbar;