import React, { useState, useEffect, createContext, lazy, Suspense } from 'react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";

import ScrollToTop from './Components/GlobalComponents/ScrollToTop';

import Home from './Components/Home';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import LoadingPage from './Components/GlobalComponents/LoadingPage';

import { TitleMap } from './utils/TitleMaps';

const IndustrySolutions = lazy(()=> import('./Components/Views/IndustrySolutions/IndustrySolutions'));
const DentalSchools = lazy(()=> import('./Components/Views/IndustrySolutions/DentalSchools'));
const FederallyQualifiedHC = lazy(()=> import('./Components/Views/IndustrySolutions/FederallyQualifiedHC'));
const GovernmentAgencies = lazy(()=> import('./Components/Views/IndustrySolutions/GovernmentAgencies'));
const MedicalImagingCenters = lazy(()=> import('./Components/Views/IndustrySolutions/MedicalImagingCenters'));
const MultiClinicDentalOrg = lazy(()=> import('./Components/Views/IndustrySolutions/MultiClinicDentalOrg'));

const ProductsOverview = lazy(()=> import('./Components/Views/Products/ProductsOverview'));
const StorageServer = lazy(()=> import('./Components/Views/Products/StorageServer'));
const DICOMRouter = lazy(()=> import('./Components/Views/Products/DICOMRouter'));
const HL7Addon = lazy(()=> import('./Components/Views/Products/HL7Addon'));
const ModalityWorklist = lazy(()=> import('./Components/Views/Products/ModalityWorklist'));
const Html5WebViewer = lazy(()=> import('./Components/Views/Products/Html5WebViewer'));
const DentalEnterpriseSolution = lazy(()=> import('./Components/Views/Products/DentalEnterpriseSolution'));
const DentalEnterpriseViewer = lazy(()=> import('./Components/Views/Products/DentalEnterpriseViewer'));
const MiPACSDemoRequest = lazy(()=> import('./Components/Views/Products/EvaluateMiPACS'));
const Compatibility = lazy(()=> import('./Components/Views/Products/Compatibility'));

const Support = lazy(()=> import('./Components/Views/Support/Support'));
const TTMeasurementCalibration = lazy(()=> import('./Components/Views/Support/TTMeasurementCalibration'));
const TTLocatingDeletedFile = lazy(()=> import('./Components/Views/Support/TTLocatingDeletedFile'));
const TTPatientBanner = lazy(()=> import('./Components/Views/Support/TTPatientIDBanner'));

const Contact = lazy(()=> import('./Components/Views/Contact/Contact'));
const EmailSubscription = lazy(()=> import('./Components/Views/Contact/EmailSubscription'));

const Corporate = lazy(()=> import('./Components/Views/Corporate/Corporate'));
const AboutMedicorImaging = lazy(()=> import('./Components/Views/Corporate/AboutMedicorImaging'));
const CareersAtMedicoImaging = lazy(()=> import('./Components/Views/Corporate/CareersAtMedicorImaging'));
const CaseStudies = lazy(()=> import('./Components/Views/Corporate/CaseStudies'));
const Events = lazy(()=> import('./Components/Views/Corporate/Events'));
const ReferencesCurrentImplementations = lazy(()=> import('./Components/Views/Corporate/ReferencesCurrentImplementations'));
const PressRoom = lazy(()=> import('./Components/Views/Corporate/PressRoom'));
const Privacy = lazy(()=> import('./Components/Views/Corporate/Privacy'));
const CLTEyesEarsNose = lazy(()=> import('./Components/Views/Corporate/CaseStudies/CLTEyeEarsNose'));
const TuftsUniversitySchool = lazy(()=> import('./Components/Views/Corporate/CaseStudies/TuftsUniversitySchool'));
const UniFloridaCollegeDentist = lazy(()=> import('./Components/Views/Corporate/CaseStudies/UniFloridaCollegeDentist'));
const UniKansasCity = lazy(()=> import('./Components/Views/Corporate/CaseStudies/UniKansasCity'));
const UniNevadaLA = lazy(()=> import('./Components/Views/Corporate/CaseStudies/UniNevadaLA'));
const UniTexasHealthScience = lazy(()=> import('./Components/Views/Corporate/CaseStudies/UniTexasHeathScience'));

const MiPACSUserMeeting = lazy(()=> import('./Components/Views/MiPACSUserMeeting/MiPACSUserMeeting'));
const Search = lazy(()=> import('./Components/Views/Support/Search'));

const LegacyRewriteMap = lazy(()=> import('./Components/LegacyRewriteMaps'));

const AnimationWrapper = styled.div`
  transition: all 750ms cubic-bezier(0.23, 1, 0.32, 1);
`;

function useWindowWidth() {

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {

    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}

export const WindowWidthContext = createContext(window.innerWidth);
export const NavSearchContext = createContext({});

const App = () => {
  const windowWidth = useWindowWidth();
  const [navSearch, setNavSearch] = useState<string>("");

  const currentLocation = useLocation();
  const currentPath = currentLocation.pathname;

  useEffect(() => {
    if (Object.keys(TitleMap).includes(currentPath)) {
      document.title = TitleMap[currentPath];
    }
    else {
      document.title = TitleMap['/'];
    }
  }, [currentPath])




  return (


    <WindowWidthContext.Provider value={windowWidth}>
      <NavSearchContext.Provider value={{ navSearch, setNavSearch }}>
        <AnimationWrapper>
          <Navbar />
          <ScrollToTop />
          <div id="Content">
            <Routes>
              {/* pageTitle: Home Page */}
              <Route path='/' element={<Home />} />

              {/* pageTitle: Industry Solutions */}
              <Route path='/industry' element={<Suspense fallback={<LoadingPage />}><IndustrySolutions /></Suspense>} />
              {/* pageTitle: Dental Schools */}
              <Route path='/industry/dental-schools' element={<Suspense fallback={<LoadingPage />}><DentalSchools /></Suspense>} />
              {/* pageTitle: Federally Qualified Health Centers */}
              <Route path='/industry/federally-qualified-health-centers' element={<Suspense fallback={<LoadingPage />}><FederallyQualifiedHC /></Suspense>} />
              {/* pageTitle: Government Agencies */}
              <Route path='/industry/government-agencies' element={<Suspense fallback={<LoadingPage />}><GovernmentAgencies /></Suspense>} />
              {/* pageTitle: Medical Imaging Centers */}
              <Route path='/industry/medical-imaging-centers' element={<Suspense fallback={<LoadingPage />}><MedicalImagingCenters /></Suspense>} />
              {/* pageTitle: Multi-Clinic Dental Organizations */}
              <Route path='/industry/multi-clinic-dental-organizations' element={<Suspense fallback={<LoadingPage />}><MultiClinicDentalOrg /></Suspense>} />

              {/* pageTitle: Products Overview */}
              <Route path='/products' element={<Suspense fallback={<LoadingPage />}><ProductsOverview /></Suspense>} />
              {/* pageTitle: MiPACS Storage Server */}
              <Route path='/products/mipacs-storage-server' element={<Suspense fallback={<LoadingPage />}><StorageServer /></Suspense>} />
              {/* pageTitle: Dicom Router+ Add-on */}
              <Route path='/products/mipacs-dicom-router' element={<Suspense fallback={<LoadingPage />}><DICOMRouter /></Suspense>} />
              {/* pageTitle: MiPACS Storage Server HL7 Add-on */}
              <Route path='/products/mipacs-hl7-addon' element={<Suspense fallback={<LoadingPage />}><HL7Addon /></Suspense>} />
              {/* pageTitle: MiPACS Modality Worklist */}
              <Route path='/products/mipacs-modality-worklist' element={<Suspense fallback={<LoadingPage />}><ModalityWorklist /></Suspense>} />
              {/* pageTitle: MiPACS HTML5 Web Viewer */}
              <Route path='/products/mipacs-html5-web-viewer' element={<Suspense fallback={<LoadingPage />}><Html5WebViewer /></Suspense>} />
              {/* pageTitle: MiPACS Dental Enterprise Solution */}
              <Route path='/products/mipacs-dental-enterprise-solution' element={<Suspense fallback={<LoadingPage />}><DentalEnterpriseSolution /></Suspense>} />
              {/* pageTitle: MiPACS Dental Enterprise Viewer */}
              <Route path='/products/mipacs-dental-enterprise-viewer' element={<Suspense fallback={<LoadingPage />}><DentalEnterpriseViewer /></Suspense>} />
              {/* pageTitle: MiPACS Demo Request */}
              <Route path='/products/mipacs-demo-request' element={<Suspense fallback={<LoadingPage />}><MiPACSDemoRequest /></Suspense>} />
              {/* pageTitle: MiPACS Dental Enterprise Viewer - Compatible Devices */}
              <Route path='/products/compatibility' element={<Suspense fallback={<LoadingPage />}><Compatibility /></Suspense>} />

              {/* pageTitle: Support */}
              <Route path='/support' element={<Suspense fallback={<LoadingPage />}><Support /></Suspense>} />
              {/* pageTitle: Measurement Calibration */}
              <Route path='/support/measurement-calibration' element={<Suspense fallback={<LoadingPage />}><TTMeasurementCalibration /></Suspense>} />
              {/* pageTitle: Locating a Deleted File */}
              <Route path='/support/locating-deleted-files' element={<Suspense fallback={<LoadingPage />}><TTLocatingDeletedFile /></Suspense>} />
              {/* pageTitle: Using the Patient ID Banner */}
              <Route path='/support/using-patient-id-banner' element={<Suspense fallback={<LoadingPage />}><TTPatientBanner /></Suspense>} />

              {/* pageTitle: Contact */}
              <Route path='/contact' element={<Suspense fallback={<LoadingPage />}><Contact /></Suspense>} />
              {/* pageTitle: Email Subscription */}
              <Route path='/contact/subscription' element={<Suspense fallback={<LoadingPage />}><EmailSubscription /></Suspense>} />

              {/* pageTitle: Corporate */}
              <Route path='/corporate' element={<Suspense fallback={<LoadingPage />}><Corporate /></Suspense>} />
              {/* pageTitle: About Medicor Imaging */}
              <Route path='/corporate/about' element={<Suspense fallback={<LoadingPage />}><AboutMedicorImaging /></Suspense>} />
              {/* pageTitle: Careers at Medicor Imaging */}
              <Route path='/corporate/careers' element={<Suspense fallback={<LoadingPage />}><CareersAtMedicoImaging /></Suspense>} />
              {/* pageTitle: Events */}
              <Route path='/corporate/events' element={<Suspense fallback={<LoadingPage />}><Events /></Suspense>} />
              {/* pageTitle: References and Current Implementations */}
              <Route path='/corporate/references' element={<Suspense fallback={<LoadingPage />}><ReferencesCurrentImplementations /></Suspense>} />
              {/* pageTitle: Press Room */}
              <Route path='/corporate/pressroom' element={<Suspense fallback={<LoadingPage />}><PressRoom /></Suspense>} />
              {/* pageTitle: MEDICOR IMAGING Privacy Policy */}
              <Route path='/corporate/privacy' element={<Suspense fallback={<LoadingPage />}><Privacy /></Suspense>} />
              {/* pageTitle: Case Studies */}

              <Route path='/corporate/case-studies' element={<Suspense fallback={<LoadingPage />}><CaseStudies /></Suspense>} />
              {/* pageTitle: Charlotte Eye Ear Nose & Throat Associates */}
              <Route path='/corporate/case-studies/charlotte-eye-ears-nose-and-throat-associates' element={<Suspense fallback={<LoadingPage />}><CLTEyesEarsNose /></Suspense>} />
              {/* pageTitle: Tufts University School of Dental Medicine */}
              <Route path='/corporate/case-studies/tufts-university-school-of-dental-medicine' element={<Suspense fallback={<LoadingPage />}><TuftsUniversitySchool /></Suspense>} />
              {/* pageTitle: University of Florida College of Dentistry */}
              <Route path='/corporate/case-studies/university-of-florida-college-of-dentistry' element={<Suspense fallback={<LoadingPage />}><UniFloridaCollegeDentist /></Suspense>} />
              {/* pageTitle: University of Kansas City Missouri's School of Dentistry */}
              <Route path='/corporate/case-studies/university-of-kansas-city-missouri-school-of-dentistry' element={<Suspense fallback={<LoadingPage />}><UniKansasCity /></Suspense>} />
              {/* pageTitle: University of Nevada at Las Vegas School of Dental Medicine */}
              <Route path='/corporate/case-studies/university-of-nevada-at-las-vegas-school-of-dental-medicine' element={<Suspense fallback={<LoadingPage />}><UniNevadaLA /></Suspense>} />
              {/* pageTitle: University of Texas Health and Science Center at San Antonio (UTHSCSA) */}
              <Route path='/corporate/case-studies/university-of-texas-health-and-science-center-at-san-antonio' element={<Suspense fallback={<LoadingPage />}><UniTexasHealthScience /></Suspense>} />

              {/* pageTitle: MIPACS USER MEETING */}
              <Route path='/mipacs-user-meeting' element={<Suspense fallback={<LoadingPage />}><MiPACSUserMeeting /></Suspense>} />

              {/* pageTitle: Search */}
              <Route path='/search' element={<Suspense fallback={<LoadingPage />}><Search /></Suspense>} />

              <Route path='/corporate/pressroom/*' element={<Suspense fallback={<LoadingPage />}><PressRoom /></Suspense>} />

              <Route path="/*" element={<Suspense fallback={<LoadingPage />}><LegacyRewriteMap /></Suspense>} />

            </Routes>
          </div>
          <Footer />
        </AnimationWrapper>
      </NavSearchContext.Provider>
    </WindowWidthContext.Provider>

  );
}

export default App;
