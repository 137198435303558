import styled from "styled-components";
import { Link } from "react-router-dom";
import { StyleProps } from "../../Interfaces";

export const Footer = styled.nav<StyleProps>`
  width: 100%;
  background-color: #262d49;
  padding-top: ${(props) => (props.isSubPages ? "4%" : "1%")};
  position: absolute;
  bottom: 0;
`;

export const TopContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  margin: 3% auto;
  justify-content: space-between;
`;

export const FooterIconPack = styled.div`
  margin-left: 5%;
`;

export const FooterIcons = styled.a`
  margin-right: 5px;
`;

export const FooterText = styled.p`
  font-size: 14px;
`;

export const FooterSearch = styled.div`
  margin-left: auto;
  margin-right: 5%;
`;

export const LineDiv = styled.div`
  border: solid 1px silver;
  width: 80%;
  margin: auto;
  background-color: #262d49;
`;

export const BottomContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  padding-top: 3%;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  background-color: #262d49;

  @media only screen and (max-width: 850px) {
    flex-direction: column;
    padding-top: 0;
  }
`;

export const Copyright = styled.p`
  font-size: 16px;
  color: silver;

  @media only screen and (max-width: 850px) {
    font-size: 12px;
    margin: auto;
    text-align: center;
  }
`;

export const LogoImg = styled.img`
  width: 70%;
  height: auto;
  max-width: 250px;
`;

export const LocationDiv = styled.div`
  display: flex;
  flex-direction: row;
  color: silver;
  align-items: center;
  @media only screen and (max-width: 850px) {
    text-align: left;
    align-items: center;
  }
`;

export const LocationText = styled.p`
  font-size: 18px;
  @media only screen and (max-width: 850px) {
    font-size: 12px;
  }
`;

export const LocationImg = styled.img`
  width: 62px;
  height: 57px;
  margin-right: 20px;

  @media only screen and (max-width: 850px) {
    width: 40px;
    height: 40px;
  }
`;

export const SocialsImg = styled.img`
  width: 50px;
  height: 50px;
  margin: 0px 5px;

  :hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 850px) {
    height: 40px;
    width: 40px;
  }
`;

export const FooterBrand = styled.a``;

export const FooterLinksList = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
  @media only screen and (max-width: 850px) {
    flex-direction: column;
    margin: 5px 0;
  }
`;
export const FooterLinks = styled.div`
  font-weight: bolder;
  color: #959698;
  opacity: 0.8;
`;
export const FooterLinkText = styled.p`
  margin: auto;
  position: relative;
  :hover {
    cursor: pointer;
    color: #feffff;
    :after {
      border-right: solid 15px #feffff;
      border-left: solid 15px #feffff;
      border-top: solid 5px #feffff;
      transform: translateX(-50%);
      position: absolute;
      z-index: -1;
      content: "";
      top: 200%;
      left: 50%;
      height: 0;
      width: 0;
    }
  }
  @media only screen and (max-width: 850px) {
  }
`;

export const MobileLinkText = styled.p`
  margin: auto;
  position: relative;
  padding-top: 10px;
  :hover {
    cursor: pointer;
    color: #feffff;
  }
`;

export const MobileBrandWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MobileLinkListWrap = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  padding-right: 0;
  font-size: 12px;
  flex-direction: row;
`;

export const MobileIconTextWrapper = styled.div<StyleProps>`
  display: flex;
  flex-direction: row;
`;

export const LinkLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

export const LinkA = styled.a`
  text-decoration: none;
  color: inherit;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

export const SocialsDiv = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 850px) {
  }
`;
