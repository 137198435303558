import styled, { StyledProps } from "styled-components";
import { StyleProps } from '../../Interfaces';

export const MainDiv = styled.div`
    display:flex;
    flex-direction:column;
    padding-bottom: 5%;
    @media only screen and (max-width: 1200px ) {
            padding-bottom: 0;
    }
    
`;

export const ImageH2 = styled.h2`
    font-size: 28px;
    @media only screen and (max-width: 850px ) {
            font-size: 16px;
        }
`;

export const ImageH1 = styled.h1`
    font-size: 42px;
    font-weight: bold;
    @media only screen and (max-width: 850px ) {
            font-size: 20px;
        }
`;

export const ImageH3 = styled.h2`
    font-size: 22px;
    @media only screen and (max-width: 850px ) {
            font-size: 18px;
        }
`;

export const ImageTextWrapper = styled.div`
    text-align: left;
    margin-left: 10%;
    width: 80%;
`;

export const ImageButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    
`;

export const ImageButton = styled.button<StyleProps>`
    background-color: ${props => props.inverted ? "#feffff" : "#015dbc"};
    color: ${props => props.inverted ? "#015dbc" : "#feffff"};
    width: fit-content;
    padding: 10px 25px;
    margin-right: 10px;
    border-radius: 20px;
    border: 1px solid transparent;
    :hover{
        cursor: pointer;
    }
`;

export const ServiceBlocks = styled.div`
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 150px;
    transform: translateY(-60%);
    @media only screen and (max-width:850px ) {
        width: 90%;
        transform: translateY(-115%);
        height: 75px;
        margin: 5px auto;
    }
`;

export const ServiceBlock = styled.p<StyleProps>`
    background-color: ${props => props.bColor};
    align-items: center;
    display: flex;
    max-width: 200px;
    text-align: center;
    height: 100%;
    padding: 0px 3%;
    font-size: 22px;
    color: #feffff;
    flex-wrap: wrap;
    filter: ${props => props.bShadow};

    :hover{
        cursor: pointer;
        filter: brightness(80%);
    }

    @media only screen and (max-width:850px ) {
        font-size: 12px;
        width: 150px;
        margin: auto 5px;
    }
`;

export const ServiceSquareLittleMoreWrapper = styled.div`
    width: 50%;
    margin: 2.25% 0 0 50%;
    text-align: left;
    @media only screen and (max-width:850px ) {
        width: 80%;
        margin: -15% auto 0;
    }
    
`;

export const ServiceSquareLittleMoreH2 = styled.h2`
    font-size: 26px;
    @media only screen and (max-width:850px ) {
        font-size: 14px;
    }
    
`;
export const ServiceSquareLittleMoreLink = styled.a`
    display: flex;
    font-size: 20px;
    align-items: center;
    margin-right: 15px;
    color: #8fd7fe;

    @media only screen and (max-width:850px ) {
        font-size: 12px;
    }
`;

export const ContactBlurb = styled.div`
    display: flex;
    align-items: center;
    width: 50%;
    margin: 1% 0 15% 20%;
    @media only screen and (max-width:850px ) {
        width: 90%;
        margin: auto auto auto 25px;
        justify-content: center;
    }
`;

export const ContactBlurbImg = styled.img`
    width: 100px;
    height: 100px;
    @media only screen and (max-width:850px ) {
        width: 75px;
        height: 75px;
    }
`;

export const LearnMoreImg = styled.img`
    width: 50px;
    height: 60px;

    :hover{
        cursor: pointer;
    }
`;

export const ContactBlurbText = styled.h2`
    font-size: 22px;
    margin-left: 30px;
    @media only screen and (max-width:850px ) {
        font-size: 14px;

    }
`;

export const StatBlocksWrapper = styled.div`
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    height: 10%;
    background-color: #f3f2f2;
    @media only screen and (max-width:850px ) {
        font-size: 14px;
        flex-direction: row;
        width: 90%;
        justify-content: center;
        height: auto;
    }
`;

export const StatBlocksWrapperMobile = styled.div`
    width: 80%;
    margin: 150px auto 125px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    
`;

export const StatBlocks = styled.div`
    width: 100%;
    postion: relative;
    margin-top: -100px;
    display: flex;
    /* margin: auto; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 200px;
    /* transform: translateY(-15vh); */
    @media only screen and (max-width:850px ) {
        flex-direction: column;
        position: static;
        margin: 0px;
        height: 100px;
        width: 60%;
    }
`;

export const StatBlock = styled.div<StyleProps>`
    background-color: ${props => props.bColor};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 100%;
    padding: 25px 50px;
    margin: 0px 10px;
    font-size: 22px;
    box-shadow: ${props => props.bShadow};

    h3{
        font-size: 32px;
        margin: 5px;
        @media only screen and (max-width:850px ) {
            font-size: 20px;
        }
    }

    p{
        margin: 5px;
        @media only screen and (max-width:850px ) {
            font-size: 14px;
            margin: auto;
        }
    }

    @media only screen and (max-width:850px ) {
        width: 115px;
        padding: 15px 25px;
        margin: 05px 10px;
    }
`;

export const StatBlockImg = styled.img`
    width: 50px;
    height: 50px;
`;

export const TestimonialSnippet = styled.h2`
    margin-top: 3%;
    margin-left: 15%;
`

export const TestimonialWrapper = styled.div`
    margin: 3% 3% 6%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;

    @media only screen and (max-width:850px ) {
        flex-direction: column;
        margin: auto;
    }
`

export const PoweredBy = styled.div`
    margin: 3% 5%;
    display: flex;
    flex-direction: column;
    text-align: center;

    h1{
        font-size: 56px;
        letter-spacing: .1rem;
        @media only screen and (max-width: 850px ) {
            font-size: 18px;
            text-align: left;
        }
    }

    p{
        font-size: 26px;
        color: grey;
        @media only screen and (max-width: 850px ) {
            font-size: 12px;
        }
    }

    a{
        text-decoration: none;
        color: inherit;
        font-weight: bold;
        :hover{
            cursor: pointer;
            filter: brightness(60%);
        }
        @media only screen and (max-width: 850px ) {
            font-size: 12px;
        }
    }
`;

export const PoweredByImg = styled.img`
    margin-left: 50px;
    width: 25%;

    :hover{
        cursor: pointer;
        filter: brightness(80%);
    }
    @media only screen and (max-width: 850px ) {
        width: 50%;
        margin-left: 5px;
    }
`

export const PoweredByWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: auto;
    align-items: center;
    justify-content: center;

    
`

export const SignUpWrapper = styled.div`
    width: 80%;
    height: 250px;
    background-color: #2d38a4;
    margin: auto;
    position: relative;
    top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 5%;
    z-index: 5;
    border-radius: 125px 125px 0 0 ;

    /* 

    @media only screen and (max-width: 1850px ) {
         height: 220px;
        margin: 0px auto -200px auto;
    }

     */

    @media only screen and (min-width: 2400px) {
        width: 80%;
        top: -50px;
    }
    @media only screen and (max-width: 1200px ) {
        width: 90%;
        margin: auto;
        font-size: 16px;
        height: 220px;
        border-radius: 0px;
        top: 0;
    }
`;

export const SignUpDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
    @media only screen and (min-width: 2400px) {
        
        width: 90%;
    }

    @media only screen and (max-width: 1850px ) {
         width: 90%;
    }


    @media only screen and (max-width: 1200px ) {
        flex-direction: column;
        justify-content: center;
    }
`;

export const SignUpButton = styled.button`
    height: 80px;
    width: 250px;
    font-size: 26px;
    background-color: #37b4fe;
    color: #feffff;
    border-radius: 10px;
    :hover{
        cursor: pointer;
    }
    border-color: transparent;

    @media only screen and (max-width: 1850px ) {
        font-size: 22px;
        height: 60px;
        width: 100px;
    }

    @media only screen and (max-width: 1200px ) {
        font-size: 16px;
        height: 35px;
        width: 100px;
        margin-top: 5px;
    }

`;

export const SignUpText = styled.h1`
    color: #feffff;
    font-size: 36px;
    @media only screen and (min-width: 2400px) {
        
        font-size: 42px;
    }

    @media only screen and (max-width: 1850px ) {
         font-size: 24px;
    }

    @media only screen and (max-width: 1200px ) {
        font-size: 16px;
        
    }
`;

export const SignUpInput = styled.input`
    height: 80px;
    width: 700px;
    border-radius: 10px;
    font-size: 32px;
    background-color: transparent;
    border: 1px solid #feffff;
    color: #feffff;
    padding-left: 25px;
    ::placeholder{
        color: #feffff;
    }

    @media only screen and (min-width: 2400px) {
        
        width: 900px;
        font-size: 36px;
    }

    @media only screen and (max-width: 1850px ) {
        width: 400px;
        font-size: 24px;
        height: 60px;
    }

    @media only screen and (max-width: 1200px ) {
        flex-direction: column;
        padding-left: 15px;
        width: 175px;
        font-size: 14px;
        height: 25px;
    }
`;